.header {
	position: relative;
	@media (min-width: 992px) {
		display: flex;
		align-items: center;
		min-height: 100vh;
		padding-top: 150px; }
	.header-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 60vw;
		min-height: 300px;
		padding: 20px;
		background-image: url("../img/keyvisual.jpg");
		background-position: top center;
		background-size: contain;
		background-repeat: no-repeat;
		@media (min-width: 992px) {
			height: 100%;
			background-size: cover;
			background-position: 50% 50%; } }
	.form-section {
		position: relative;
		max-width: 1400px;
		width: 100%;
		margin: 0 auto;
		padding-top: 45vw;
		@media (min-width: 992px) {
			padding-top: 0;
			height: 100%;
			display: flex;
			align-items: center; } } }

.stickies {
	background: $orange;
	position: fixed;
	border: 2px solid white;
	box-shadow: 0 0 1.5rem rgba(black, 0.15);
	@media (max-width: 991px) {
		display: flex;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		width: 16rem;
		border-bottom: none; }
	@media (min-width: 992px) {
		border-right: none;
		right: 0;
		top: 50%;
		transform: translateY(-50%); }
	.sticky-item {
		@media (min-width: 992px) {
			// padding: 1rem
			width: 9rem;
			height: 9rem; }
		flex: 1 0 30%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		img {
			display: block;
			max-width: 100%; }
		p {
			color: black;
			display: none;
			margin-top: -1rem;
			@media (min-width: 992px) {
				display: block; } }
		&:last-child {
			padding: 0.5rem;
			@media (min-width: 992px) {
				padding: 1rem; } } } }


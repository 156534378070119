* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body {
  height: 100%;
  font-size: 10px;
  line-height: 1.4;
  scroll-behavior: smooth; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }



.main-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 2rem;
	border-radius: 5rem;
	color: black;
	transition: all 300ms ease;
	cursor: pointer;
	background: $orange;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 1.6rem;
	&:hover {
		background: darken($orange, 15%); }
	&.disabled {
		opacity: 0.8;
		pointer-events: none; } }

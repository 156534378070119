@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body {
  height: 100%;
  font-size: 10px;
  line-height: 1.4;
  scroll-behavior: smooth; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

.main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 5rem;
  color: black;
  transition: all 300ms ease;
  cursor: pointer;
  background: #FDCC70;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.6rem; }
  .main-button:hover {
    background: #fcb125; }
  .main-button.disabled {
    opacity: 0.8;
    pointer-events: none; }

form[data-housetype="Einfamilienhaus"] [data-selection="Mehrfamilienhaus"] {
  display: none; }

form[data-housetype="Mehrfamilienhaus"] [data-selection="Einfamilienhaus"] {
  display: none; }

form[data-type="Informationen"] [data-art="Beratungsgespräch"] {
  display: none; }

form[data-type="Beratungsgespräch"] [data-art="Informationen"] {
  display: none; }

form p[data-error] span {
  display: block;
  color: red;
  margin-top: -1.5rem;
  margin-left: 1rem; }

form p[data-error="consent"] span {
  display: block;
  margin-top: 1rem;
  margin-left: 0; }

form .main-button {
  width: 100%;
  outline: none;
  border: none; }

form .input--grid {
  display: flex;
  flex-wrap: wrap; }
  form .input--grid > div {
    flex: 1 0 45%;
    margin-bottom: 2rem; }
    form .input--grid > div:nth-child(odd) {
      margin-right: 1rem; }

form .input-group input {
  margin: 1rem 0 2rem;
  font-size: 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 5rem;
  border: 1px solid #3b5372;
  color: #3b5372;
  transition: all 300ms ease;
  text-align: center;
  outline: none; }
  form .input-group input:focus {
    border-color: #FDCC70; }

form .checkbox-group {
  margin-bottom: 2rem; }
  form .checkbox-group label {
    position: relative;
    display: flex;
    align-items: center; }
    form .checkbox-group label a {
      color: inherit;
      display: inline-block;
      margin-left: 0.5rem;
      text-decoration: underline; }
    form .checkbox-group label:before {
      content: "";
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3b5372;
      background: white;
      cursor: pointer;
      margin-right: 1rem; }
  form .checkbox-group input {
    display: none; }
    form .checkbox-group input:checked ~ label:before {
      background: #3b5372; }

form .radio-button .radio--button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 5rem;
  border: 1px solid #3b5372;
  color: #3b5372;
  transition: all 300ms ease;
  cursor: pointer; }
  form .radio-button .radio--button:hover {
    background: #4c6b94;
    color: white; }

form .radio-button input {
  display: none; }
  form .radio-button input:checked ~ .radio--button {
    background: #3b5372;
    border-color: #3b5372;
    color: white; }
    form .radio-button input:checked ~ .radio--button:hover {
      color: white; }

form .form-step {
  display: none; }
  form .form-step.active {
    display: block; }

form #bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem; }
  form #bullets .bullet {
    margin: 0 0.5rem;
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    border: 1px solid #3b5372;
    background: white;
    transition: all 300ms ease;
    pointer-events: none; }
    form #bullets .bullet.active {
      pointer-events: all;
      background: #3b5372; }
    form #bullets .bullet.activated {
      pointer-events: all;
      cursor: pointer; }

.sent--container * {
  text-align: center; }

.sent--container p {
  margin-bottom: 2rem; }

.header {
  position: relative; }
  @media (min-width: 992px) {
    .header {
      display: flex;
      align-items: center;
      min-height: 100vh;
      padding-top: 150px; } }
  .header .header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60vw;
    min-height: 300px;
    padding: 20px;
    background-image: url("../img/keyvisual.jpg");
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat; }
    @media (min-width: 992px) {
      .header .header-image {
        height: 100%;
        background-size: cover;
        background-position: 50% 50%; } }
  .header .form-section {
    position: relative;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding-top: 45vw; }
    @media (min-width: 992px) {
      .header .form-section {
        padding-top: 0;
        height: 100%;
        display: flex;
        align-items: center; } }

.stickies {
  background: #FDCC70;
  position: fixed;
  border: 2px solid white;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15); }
  @media (max-width: 991px) {
    .stickies {
      display: flex;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 16rem;
      border-bottom: none; } }
  @media (min-width: 992px) {
    .stickies {
      border-right: none;
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  .stickies .sticky-item {
    flex: 1 0 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media (min-width: 992px) {
      .stickies .sticky-item {
        width: 9rem;
        height: 9rem; } }
    .stickies .sticky-item img {
      display: block;
      max-width: 100%; }
    .stickies .sticky-item p {
      color: black;
      display: none;
      margin-top: -1rem; }
      @media (min-width: 992px) {
        .stickies .sticky-item p {
          display: block; } }
    .stickies .sticky-item:last-child {
      padding: 0.5rem; }
      @media (min-width: 992px) {
        .stickies .sticky-item:last-child {
          padding: 1rem; } }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background-color: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-size: 1em; }

button, input, optgroup, select, textarea {
  margin: 0; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%; }

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec; }

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec; }

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 900; }

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center; }

a {
  color: #fff;
  text-decoration: none; }

li {
  margin-bottom: 10px;
  margin-left: 0px;
  padding-left: 40px;
  background-image: url("../img/check.svg");
  background-position: 0% 0px;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  list-style-type: none; }

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.brand {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
  width: 100px; }
  @media (min-width: 992px) {
    .brand {
      width: 150px; } }

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  background-color: transparent;
  pointer-events: none;
  z-index: 10; }

.form-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent; }

.form-container {
  max-width: 450px;
  padding: 30px;
  border-radius: 20px;
  background-color: #f1fafa; }

.heading {
  margin-top: 0px;
  font-family: Lato, sans-serif;
  color: #3b5372;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: 0; }

.heading-2 {
  margin-top: 0px; }

.wave-divider {
  margin-top: 20px;
  margin-bottom: 20px; }

.wave-divider.centered {
  display: block;
  margin: 40px auto;
  text-align: center; }

.body {
  font-family: Lato, sans-serif;
  font-size: 16px;
  line-height: 1.5; }

.main-button.btn-content {
  display: inline-block;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  -o-object-fit: none;
  object-fit: none; }

.radio-button {
  margin-bottom: 20px; }

.radio-button-2 {
  display: none;
  overflow: visible; }

.radio-label-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #3b5372;
  border-radius: 100px;
  background-color: #fff; }

.radio-button-field {
  padding-left: 0px; }

.bold-text {
  display: block;
  margin-bottom: 20px; }

.main-section {
  padding: 5vw 20px;
  background-color: #3b5372; }

.main-section.grey {
  background-color: #f9f9f9; }

.content {
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto; }

.content.centered {
  text-align: center; }

.heading-3 {
  color: #fff;
  font-size: 40px;
  line-height: 1.4;
  font-weight: 900; }

.heading-3.blue {
  color: #3b5372; }

.white-paragraph {
  color: #fff;
  text-align: center; }

.steps {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1000px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "g1 g2 g3 g4 g5";
  -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto; }

.step {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.image {
  margin-bottom: 10px; }

.content-p {
  text-align: center; }

.bold-text-2 {
  text-align: center; }

.heading-4 {
  margin-bottom: 20px;
  color: #3b5372;
  font-size: 16px;
  text-align: center; }

.steps-heading {
  margin-bottom: 20px;
  color: #3b5372;
  font-size: 16px;
  line-height: 1.5;
  text-align: center; }

.fullwidth-half {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas: "image text";
  -ms-grid-columns: 1fr 0px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto; }

.background-image {
  background-image: url("../img/image-ecowater.jpg");
  background-position: 50% 50%;
  background-size: cover; }

.half-content {
  padding: 5vw; }

.content-container {
  position: relative;
  width: 100%;
  max-width: 600px; }

.content-container.half {
  max-width: 450px; }

.blue.heading-3.left {
  text-align: left; }

.list {
  margin-bottom: 0px;
  padding-left: 0px; }

.list-item {
  margin-left: 20px; }

.list-item-2 {
  margin-left: 20px; }

.list-item-3 {
  min-height: 27px; }

.products {
  display: -ms-grid;
  display: grid;
  margin-top: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto; }

.product-card {
  background-color: #fff; }

.product-title {
  padding: 10px; }

.product-title-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #3b5372; }

.product-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 250px;
  max-height: 250px;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #3b5372;
  border-bottom: 1px solid #3b5372;
  background-color: #f9f9f9; }

.image-2 {
  max-width: 60%; }

.produkt-description {
  padding: 10px; }

.icon-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left; }

.icon-row.mb {
  margin-bottom: 10px;
  text-align: left; }

.image-3 {
  margin-right: 10px; }

.icont-text {
  margin-bottom: 0px;
  color: #3b5372; }

.fullwidth-image {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5vw 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url("../img/junge-waschmaschine.jpg");
  background-position: 50% 50%;
  background-size: cover; }

.image-fullwidth {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none; }

.text-content {
  display: -ms-grid;
  display: grid;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto; }

.long-p {
  text-align: left; }

.footer {
  padding: 10px 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #3b5372;
  text-align: center; }

.footer-flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left; }

.footer-logo-link {
  max-height: 60px;
  min-width: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto; }

.footer-image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%; }

.footer-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5; }

.footer-link {
  display: block;
  margin-bottom: 10px;
  color: #444;
  text-decoration: none; }

.footer-link:hover {
  text-decoration: underline; }

.space-between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.copyright-p {
  margin-bottom: 0px;
  color: #fff;
  text-align: left; }

.link {
  text-decoration: none; }

.image-4 {
  width: 100%; }

@media screen and (max-width: 991px) {
  .brand {
    width: 120px; }
  .navbar {
    left: 20px;
    top: 0%;
    right: auto;
    bottom: auto; }
  .header {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 40vw 120px auto;
    grid-template-rows: 40vw 120px auto;
    background-color: #9cc6d7; }
  .form-section {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .steps {
    grid-template-areas: "g1 g2 ." "g4 g5 g3";
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto; }
  .fullwidth-half {
    display: block; }
  .background-image {
    min-height: 50vw; }
  .content-container.half {
    max-width: 50%; }
  .icont-text {
    line-height: 1.2; }
  .footer {
    padding-right: 20px;
    padding-left: 20px; } }

@media screen and (max-width: 767px) {
  .heading {
    font-size: 30px; }
  .heading-2 {
    font-size: 18px; }
  .content.mobile-padding {
    padding: 20px; }
  .steps {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-areas: "g1 g2 g3" ". g4 ." "g5 . .";
    -ms-grid-columns: 1fr 16px 1fr 16px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto 16px auto 16px auto;
    grid-template-rows: auto auto auto; }
  .fullwidth-half {
    display: block; }
  .background-image {
    min-height: 50vw; }
  .content-container.half {
    max-width: 100%; }
  .products {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto; }
  .fullwidth-image {
    display: block;
    padding: 0vw 0px;
    background-image: none; }
  .image-fullwidth {
    position: relative;
    display: block;
    width: 100%; }
  .text-content {
    display: block; }
  .long-p {
    margin-bottom: 40px; }
  .footer {
    padding: 40px 20px; }
  .footer-image {
    -o-object-fit: contain;
    object-fit: contain; } }

@media screen and (max-width: 479px) {
  .brand {
    max-width: 50vw; }
  .navbar {
    z-index: 20; }
  .header {
    -ms-grid-rows: 50vw 60px auto;
    grid-template-rows: 50vw 60px auto; }
  .form-container {
    width: 100%;
    max-width: 100%;
    padding: 20px; }
  .heading {
    font-size: 24px; }
  .heading-2 {
    font-size: 16px; }
  .main-button {
    padding: 10px 20px; }
  .radio-label-button {
    padding: 10px; }
  .bold-text {
    margin-bottom: 0px; }
  .paragraph {
    margin-bottom: 20px; }
  .heading-3 {
    font-size: 30px;
    line-height: 1.2; }
  .steps {
    grid-template-areas: "g1 g2" "g3 g4" "g5 .";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto; }
  .image {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8); }
  .fullwidth-half {
    display: block; }
  .background-image {
    min-height: 50vw; }
  .products {
    display: block;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto; }
  .product-card {
    margin-bottom: 20px;
    background-color: #fff; }
  .product-title {
    padding: 10px;
    border-bottom: 1px none #3b5372; }
  .product-title-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #3b5372;
    font-size: 16px; }
  .product-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #3b5372;
    border-bottom: 1px solid #3b5372; }
  .image-2 {
    max-width: 50%; }
  .produkt-description {
    padding: 10px; }
  .icon-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .icon-row.mb {
    margin-bottom: 10px; }
  .image-3 {
    margin-right: 20px; }
  .icont-text {
    margin-bottom: 0px;
    color: #3b5372;
    text-align: left; }
  .footer {
    padding-right: 20px;
    padding-left: 20px;
    text-align: left; }
  .footer-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .footer-logo-link {
    height: 60px; }
  .footer-heading {
    margin-top: 20px; }
  .space-between {
    display: block;
    text-align: center; }
  .copyright-p {
    margin-bottom: 10px;
    text-align: center; } }

#w-node-f1128a9a-3851-e48d-572c-818b0145b8ec-5599665d {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2; }

#w-node-a85de556-baf3-3ef3-8303-2c28fff16b58-5599665d {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1; }

#w-node-_5c8f24be-bb29-b301-aa4c-deb7bcdfedc8-5599665d {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: image; }

#w-node-a3c556be-9ad8-d9e9-0742-91b140459799-5599665d {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: text; }

@media screen and (max-width: 991px) {
  #w-node-f1128a9a-3851-e48d-572c-818b0145b8ec-5599665d {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
    -ms-grid-row-span: 3;
    grid-row-end: 3; }
  #w-node-a85de556-baf3-3ef3-8303-2c28fff16b58-5599665d {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 2;
    grid-row-end: 4; } }

form {
	&[data-housetype="Einfamilienhaus"] {
		[data-selection="Mehrfamilienhaus"] {
			display: none; } }
	&[data-housetype="Mehrfamilienhaus"] {
		[data-selection="Einfamilienhaus"] {
			display: none; } }
	&[data-type="Informationen"] {
		[data-art="Beratungsgespräch"] {
			display: none; } }
	&[data-type="Beratungsgespräch"] {
		[data-art="Informationen"] {
			display: none; } }
	p[data-error] {
		span {
			display: block;
			color: red;
			margin-top: -1.5rem;
			margin-left: 1rem; } }
	p[data-error="consent"] {
		span {
			display: block;
			margin-top: 1rem;
			margin-left: 0; } }

	.main-button {
		width: 100%;
		outline: none;
		border: none; }
	.input--grid {
		display: flex;
		flex-wrap: wrap;
		>div {
			flex: 1 0 45%;
			margin-bottom: 2rem;
			&:nth-child(odd) {
				margin-right: 1rem; } } }
	.input-group {
		input {
			margin: 1rem 0 2rem;
			font-size: 1.6rem;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1rem 2rem;
			border-radius: 5rem;
			border: 1px solid $main;
			color: $main;
			transition: all 300ms ease;
			text-align: center;
			outline: none;
			&:focus {
				border-color: $orange; } } }
	.checkbox-group {
		margin-bottom: 2rem;
		label {
			position: relative;
			display: flex;
			align-items: center;
			a {
				color: inherit;
				display: inline-block;
				margin-left: 0.5rem;
				text-decoration: underline; }
			&:before {
				content: "";
				position: relative;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: 1px solid $main;
				background: white;
				cursor: pointer;
				margin-right: 1rem; } }
		input {
			display: none;
			&:checked {
				~label {
					&:before {
						background: $main; } } } } }


	.radio-button {
		.radio--button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1rem 2rem;
			border-radius: 5rem;
			border: 1px solid $main;
			color: $main;
			transition: all 300ms ease;
			cursor: pointer;
			&:hover {
				background: lighten($main, 10%);
				color: white; } }
		input {
			display: none;
			&:checked {
				~.radio--button {
					background: $main;
					border-color: $main;
					color: white;
					&:hover {
						color: white; } } } } }

	.form-step {
		display: none;
		&.active {
			display: block; } }


	#bullets {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 2rem;
		.bullet {
			margin: 0 0.5rem;
			width: 16px;
			height: 16px;
			display: block;
			border-radius: 50%;
			border: 1px solid $main;
			background: white;
			transition: all 300ms ease;
			pointer-events: none;
			&.active {
				pointer-events: all;
				background: $main; }
			&.activated {
				pointer-events: all;
				cursor: pointer; } } } }
.sent--container {
	* {
		text-align: center; }
	p {
		margin-bottom: 2rem; } }
